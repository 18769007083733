import React from "react"
import { graphql } from "gatsby"
import OrganisationPage from "../social-supermarket/pages/organisations/OrganisationPage"
import { transformImage } from "../gatsby/transformers/imageTransformer"
import he from "he"
import { filterByVisibility } from "../social-supermarket/util/productUtil"
import { safeHeDecode } from "../social-supermarket/util/generalUtil"

const OrganisationTemplate = ({
  data: {
    wpOrganisation: {
      name,
      custom: { oneLiner, impactIcons, video, description, logo, banner },
    },
  },
  pageContext: { products },
}) => {
  return (
    <OrganisationPage
      banner={transformImage(banner, name)}
      description={description}
      impactIcons={impactIcons}
      logo={transformImage(logo, name)}
      name={safeHeDecode(name)}
      oneLiner={oneLiner}
      products={filterByVisibility(products, "Marketplace")}
      video={video}
    />
  )
}

export default OrganisationTemplate

export const query = graphql`
  query ($id: String!) {
    wpOrganisation(id: { eq: $id }) {
      id
      name
      slug
      custom {
        oneLiner
        impactIcons
        video
        description
        defaultSortType
        logo {
          ...UrlImageFragment
        }
        banner {
          ...UrlImageFragment
        }
      }
    }
  }
`

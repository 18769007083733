import React from "react"
import * as style from "./ColumnsLayout.module.scss"
import cx from "classnames"

const ColumnsLayout = ({ renderMajor, renderMinor, className }) => {
  return (
    <div className={cx(className, style.container)}>
      <div className={style.major}>{renderMajor()}</div>
      <div className={style.minor}>{renderMinor()}</div>
    </div>
  )
}

export default ColumnsLayout
